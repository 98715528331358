// Manage the toggle of Show private trails
document.addEventListener("DOMContentLoaded", function () {
    var textShowPrivateTrails = document.getElementById(
        "show-private-trails-text"
    );
    var toggleShowPrivateTrails = document.getElementById(
        "show-private-trails-toggle"
    );

    if (toggleShowPrivateTrails == null) {
        return;
    }

    var valueShowPrivateTrails =
        INJECT.showPrivateTrails ||
        getParameterByName("showPrivateTrails", window.location.href);

    toggleShowPrivateTrails.checked = valueShowPrivateTrails === "true";

    toggleShowPrivateTrails.addEventListener("click", showPrivateTrails);
    textShowPrivateTrails.addEventListener(
        "click",
        onClickTextShowPrivateTrails
    );

    function onClickTextShowPrivateTrails() {
        toggleShowPrivateTrails.checked = !toggleShowPrivateTrails.checked;
        showPrivateTrails();
    }

    function showPrivateTrails() {
        var isChecked = toggleShowPrivateTrails.checked.toString();

        location.href =
            "https://" +
            window.location.hostname +
            "/wikiloc/user.do?id=" +
            INJECT.userId +
            "&showPrivateTrails=" +
            isChecked +
            "&interactionOnPrivateTrailToogle=true"; //tell to the backend we have interacted with the component.
    }
});
